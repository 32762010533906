import {
  ContactUsIcon,
  CookieIcon,
  SitesIcon,
  AlaramIcon,
  UserGroupIcon,
  ReportIcon,
  ListIcon,
  // FormsIcon,
} from 'src/assets';
import { AnalyticsIcon } from 'src/assets/icons/AnalyticsIcon';
import featureFlags from '../../../feature-flags.json';
import { SITES_MANAGEMENT_PATH, SITES_MAP_PATH } from 'src/routes/config';

//NAVIGATION TITLE
const USER_MANAGEMENT_NAV_TITLE = 'User Management';
const SITES_NAV_TITLE = 'Sites';
const SETTINGS = 'Settings';
const OVERVIEW_NAV_TITLE = 'Site Overview';
const REPORTS_NAV_TITLE = 'Reports';
const ALARMS_NAV_TITLE = 'Alarms';
const TRENDS_NAV_TITLE = 'Trends';
// const FORMS_NAV_TITLE = 'Forms';

//NAVIGATION SUB-TITLE
const SITE_INFO_SUBITEM = 'Site Info';
const GATEWAYS_SUBITEM = 'Gateways';
const ASSETS_SUBITEM = 'Assets';
const ALARM_RULES_SUBITEM = 'Alarm Rules';
const INTEGRATIONS_SUBITEM = 'Integrations';
const CONTROLLERS_SUBITEM = 'Controllers';
const ALARMS_LIST_SUBITEM = 'Alarms List';
const MANAGE_ALARMS_NOTIFICATIONS_SUBITEM = 'Notification Management';
const SITE_LIST_SUBITEM = 'Sites List';
const SITE_MAP_SUBITEM = 'Sites Map';

//Use Cases: Add User Page, User Management Page (for now)
const NAV_TITLE_HEADER_CUSTOMER_ACCOUNT = '';

//Use Cases: Super Admin
const NAV_ITEMS_HEADER_HOME_PAGE = [
  {
    title: 'Global Administration',
    navItem: [
      {
        path: '/',
        title: 'Customer Account Management',
        icon: ListIcon,
        testId: 'customer-account-management-button',
        additionalLabel: false,
        subItems: [],
      },
    ],
  },
];

const NAV_ITEMS_HEADER_SITES_MANAGEMENT = [
  {
    title: '',
    navItem: [
      {
        path: '',
        title: SITES_NAV_TITLE,
        icon: SitesIcon,
        testId: 'sites-button',
        additionalLabel: false,
        subItems: [
          {
            path: SITES_MANAGEMENT_PATH,
            title: 'Sites List',
            testId: 'sites-management-button',
          },
          {
            path: SITES_MAP_PATH,
            title: 'Sites Map',
            testId: 'sites-map-button',
          },
        ],
      },
      {
        path: '/alarms',
        title: 'Alarms',
        icon: AlaramIcon,
        testId: 'alarms-button',
        additionalLabel: true,
        subItems: [],
      },
      {
        path: '/trends',
        title: TRENDS_NAV_TITLE,
        icon: AnalyticsIcon,
        testId: 'analytics-button',
        additionalLabel: false,
        subItems: [],
      },
      {
        path: '/reports',
        title: REPORTS_NAV_TITLE,
        icon: ReportIcon,
        testId: 'reports-button',
        additionalLabel: false,
        subItems: [],
      },
      {
        path: '/user-accounts',
        title: USER_MANAGEMENT_NAV_TITLE,
        icon: UserGroupIcon,
        testId: 'administration-button',
        additionalLabel: false,
        subItems: [],
      },
    ],
  },
];

const getNavItemsHeaderSiutesManagementSAAndAA = (user: any, isSuperAdmin: boolean) => {
  const isAlarmNotificationsFeatureFlagOn =
    featureFlags.alarmNotifications === 1 ||
    featureFlags.userIds.includes(user.userId) ||
    isSuperAdmin;
  return [
    {
      title: '',
      navItem: [
        {
          path: '',
          title: SITES_NAV_TITLE,
          icon: SitesIcon,
          testId: 'sites-button',
          additionalLabel: false,
          subItems: [
            {
              path: SITES_MANAGEMENT_PATH,
              title: 'Sites List',
              testId: 'sites-management-button',
            },
            {
              path: SITES_MAP_PATH,
              title: 'Sites Map',
              testId: 'sites-map-button',
            },
          ],
        },
        {
          path: isAlarmNotificationsFeatureFlagOn ? '' : '/alarms',
          title: 'Alarms',
          icon: AlaramIcon,
          testId: 'alarms-button',
          additionalLabel: true,
          subItems: isAlarmNotificationsFeatureFlagOn
            ? [
                {
                  path: '/alarms',
                  title: 'Alarms List',
                  testId: 'alarms-list-button',
                },
                {
                  path: '/alarms-notifications',
                  title: 'Notification Management',
                  testId: 'alarms-notifications-button',
                },
              ]
            : [],
        },
        {
          path: '/trends',
          title: TRENDS_NAV_TITLE,
          icon: AnalyticsIcon,
          testId: 'analytics-button',
          additionalLabel: false,
          subItems: [],
        },
        {
          path: '/reports',
          title: REPORTS_NAV_TITLE,
          icon: ReportIcon,
          testId: 'reports-button',
          additionalLabel: false,
          subItems: [],
        },
        // {
        //   path: '/forms',
        //   title: FORMS_NAV_TITLE,
        //   icon: FormsIcon,
        //   testId: 'forms-button',
        //   additionalLabel: false,
        //   subItems: [],
        // },
        {
          path: '/user-accounts',
          title: USER_MANAGEMENT_NAV_TITLE,
          icon: UserGroupIcon,
          testId: 'administration-button',
          additionalLabel: false,
          subItems: [],
        },
      ],
    },
  ];
};

const NAV_ITEMS_FOOTER = [
  {
    path: 'https://www.streametric.io/support',
    title: 'Support',
    icon: ContactUsIcon,
    isActive: false,
    testId: 'support-button',
  },
  {
    path: '/cookies',
    title: 'Cookies',
    icon: CookieIcon,
    testId: 'cookies-button',
  },
];

const NAV_ITEMS_HEADER_SELECTED_SITE = [
  {
    title: '',
    navItem: [
      {
        path: '',
        title: SITES_NAV_TITLE,
        icon: SitesIcon,
        testId: 'sites-button',
        additionalLabel: false,
        subItems: [
          {
            path: SITES_MANAGEMENT_PATH,
            title: 'Sites List',
            testId: 'sites-management-button',
          },
          {
            path: SITES_MAP_PATH,
            title: 'Sites Map',
            testId: 'sites-map-button',
          },
        ],
      },
      {
        path: '/alarms',
        title: 'Alarms',
        icon: AlaramIcon,
        testId: 'alarms-button',
        additionalLabel: true,
        subItems: [],
      },
      {
        path: '/trends',
        title: TRENDS_NAV_TITLE,
        icon: AnalyticsIcon,
        testId: 'analytics-button',
        additionalLabel: false,
        subItems: [],
      },
      {
        path: '/reports',
        title: REPORTS_NAV_TITLE,
        icon: ReportIcon,
        testId: 'reports-button',
        additionalLabel: false,
        subItems: [],
      },
      {
        path: '/user-accounts',
        title: USER_MANAGEMENT_NAV_TITLE,
        icon: UserGroupIcon,
        testId: 'administration-button',
        additionalLabel: false,
        subItems: [],
      },
    ],
  },
  // {
  //   title: 'Selected Site',
  //   navItem: [
  //     {
  //       path: '/sites-management/dashboard',
  //       title: 'Site Overview',
  //       icon: Dashboard,
  //       testId: 'overview-button',
  //       additionalLabel: false,
  //       subItems: [],
  //     },
  //     {
  //       path: '',
  //       title: SETTINGS,
  //       icon: Settings,
  //       testId: 'settings-button',
  //       additionalLabel: false,
  //       subItems: [
  //         {
  //           path: '/site-info',
  //           title: 'Site Info',
  //           testId: 'site-info-button',
  //         },
  //         {
  //           path: '/gateways',
  //           title: 'Gateways',
  //           testId: 'gateways-management-button',
  //         },
  //         {
  //           path: '/integrations',
  //           title: 'Integrations',
  //           testId: 'data-sources-management-button',
  //         },
  //         ...(featureFlags.controllers === 1
  //           ? [
  //               {
  //                 path: '/controllers',
  //                 title: 'Controllers',
  //                 testId: 'controllers-management-button',
  //               },
  //             ]
  //           : []),
  //         {
  //           path: '/assets',
  //           title: 'Assets',
  //           testId: 'assets-management-button',
  //         },
  //         {
  //           path: '/alarm-rules',
  //           title: 'Alarm Rules',
  //           testId: 'alarm-rules-button',
  //         },
  //       ],
  //     },
  //   ],
  // },
];

const getNavItemsHeaderSelectedSiteSAAndAA = (user: any, isSuperAdmin: boolean) => {
  const isAlarmNotificationsFeatureFlagOn =
    featureFlags.alarmNotifications === 1 ||
    featureFlags.userIds.includes(user.userId) ||
    isSuperAdmin;
  return [
    {
      title: '',
      navItem: [
        {
          path: '',
          title: SITES_NAV_TITLE,
          icon: SitesIcon,
          testId: 'sites-button',
          additionalLabel: false,
          subItems: [
            {
              path: SITES_MANAGEMENT_PATH,
              title: 'Sites List',
              testId: 'sites-management-button',
            },
            {
              path: SITES_MAP_PATH,
              title: 'Sites Map',
              testId: 'sites-map-button',
            },
          ],
        },
        {
          path: isAlarmNotificationsFeatureFlagOn ? '' : '/alarms',
          title: 'Alarms',
          icon: AlaramIcon,
          testId: 'alarms-button',
          additionalLabel: true,
          subItems: isAlarmNotificationsFeatureFlagOn
            ? [
                {
                  path: '/alarms',
                  title: 'Alarms List',
                  testId: 'alarms-list-button',
                },
                {
                  path: '/alarms-notifications',
                  title: 'Notification Management',
                  testId: 'alarms-notifications-button',
                },
              ]
            : [],
        },
        {
          path: '/trends',
          title: TRENDS_NAV_TITLE,
          icon: AnalyticsIcon,
          testId: 'analytics-button',
          additionalLabel: false,
          subItems: [],
        },
        {
          path: '/reports',
          title: REPORTS_NAV_TITLE,
          icon: ReportIcon,
          testId: 'reports-button',
          additionalLabel: false,
          subItems: [],
        },
        {
          path: '/user-accounts',
          title: USER_MANAGEMENT_NAV_TITLE,
          icon: UserGroupIcon,
          testId: 'administration-button',
          additionalLabel: false,
          subItems: [],
        },
      ],
    },
    // {
    //   title: 'Selected Site',
    //   navItem: [
    //     {
    //       path: '/sites-management/dashboard',
    //       title: 'Site Overview',
    //       icon: Dashboard,
    //       testId: 'overview-button',
    //       additionalLabel: false,
    //       subItems: [],
    //     },
    //     {
    //       path: '',
    //       title: SETTINGS,
    //       icon: Settings,
    //       testId: 'settings-button',
    //       additionalLabel: false,
    //       subItems: [
    //         {
    //           path: '/site-info',
    //           title: 'Site Info',
    //           testId: 'site-info-button',
    //         },
    //         {
    //           path: '/gateways',
    //           title: 'Gateways',
    //           testId: 'gateways-management-button',
    //         },
    //         {
    //           path: '/integrations',
    //           title: 'Integrations',
    //           testId: 'data-sources-management-button',
    //         },
    //         ...(featureFlags.controllers === 1
    //           ? [
    //               {
    //                 path: '/controllers',
    //                 title: 'Controllers',
    //                 testId: 'controllers-management-button',
    //               },
    //             ]
    //           : []),
    //         {
    //           path: '/assets',
    //           title: 'Assets',
    //           testId: 'assets-management-button',
    //         },
    //         {
    //           path: '/alarm-rules',
    //           title: 'Alarm Rules',
    //           testId: 'alarm-rules-button',
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];
};

const NAV_FOOTER_TEXT = `STREAMETRIC® 2018-${new Date().getFullYear()}`;

export {
  NAV_ITEMS_HEADER_HOME_PAGE,
  NAV_TITLE_HEADER_CUSTOMER_ACCOUNT,
  NAV_ITEMS_FOOTER,
  NAV_FOOTER_TEXT,
  SITES_NAV_TITLE,
  NAV_ITEMS_HEADER_SELECTED_SITE,
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  SITE_INFO_SUBITEM,
  OVERVIEW_NAV_TITLE,
  SETTINGS,
  GATEWAYS_SUBITEM,
  ASSETS_SUBITEM,
  REPORTS_NAV_TITLE,
  ALARM_RULES_SUBITEM,
  USER_MANAGEMENT_NAV_TITLE,
  SITE_MAP_SUBITEM,
  SITE_LIST_SUBITEM,
  ALARMS_NAV_TITLE,
  TRENDS_NAV_TITLE,
  INTEGRATIONS_SUBITEM,
  CONTROLLERS_SUBITEM,
  ALARMS_LIST_SUBITEM,
  MANAGE_ALARMS_NOTIFICATIONS_SUBITEM,
  getNavItemsHeaderSiutesManagementSAAndAA,
  getNavItemsHeaderSelectedSiteSAAndAA,
};
