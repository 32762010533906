export enum FieldType {
  SELECTION = 'selection',
  DATA_ENTRY = 'data_entry',
  ASSET = 'asset',
  NOTE = 'notes',
  ATTACHMENT = 'attachment',
}

export interface FieldValidators {
  required: boolean;
  maxLength: number;
  minLength: number;
  max: number;
  min: number;
}

export interface AttachmendValidators extends FieldValidators {
  allowed_mime_types: string[];
}

export interface ICustomFieldBaseProps<T extends Field> {
  field: T;
  blockElement?: boolean;
  fieldsLength: number;
}

export type Option = {
  value: string;
  label: string;
  order: number;
};

export interface BaseField {
  name?: string;
  order: number;
  id: string;
}
export enum EventTypes {
  DATA_ENTRY = 'data_entry',
  MAINTENANCE = 'maintenance',
  INSPECTION = 'inspection',
  CLEANING = 'cleaning',
}

export interface SelectField extends BaseField {
  type: FieldType.SELECTION;
  is_multi?: boolean;
  options: Option[];
  validations?: Partial<FieldValidators>;
}

export interface DataEntryField extends BaseField {
  measurement: string;
  defaultUnit: string;
  location: string;
  name?: never;
  isTime?: boolean;
  type: FieldType.DATA_ENTRY;
  validations?: Partial<FieldValidators>;
}

export interface AssetField extends BaseField {
  type: FieldType.ASSET;
  options?: never;
  assetTypes: string[];
  validations?: Partial<FieldValidators>;
}

export interface NotesField extends BaseField {
  type: FieldType.NOTE;
  validations?: Partial<FieldValidators>;
}

export interface Measurement {
  name: string;
  units?: string;
  note?: string;
}

export interface AttachmentField extends BaseField {
  type: FieldType.ATTACHMENT;
  validations?: Partial<AttachmendValidators>;
}

export type Field = SelectField | AssetField | AttachmentField | DataEntryField | NotesField;

export type Element = Field | Section;

export interface BaseSection {
  order: number;
  id: string;
  fields: Field[];
  name: string;
}

export type Section = BaseSection;

export interface FormSchema {
  id?: string;
  customer_id: string;
  site_type?: string;
  event_type: EventTypes;
  date_type: 'DATE' | 'DATE_RANGE';
  description: string;
  name: string;
  is_default?: boolean;
  created_at?: string;
  updated_at?: string;
  allow_comments: boolean;
  elements: Element[];
}

export function isAssetField(element: BaseField): element is AssetField {
  return (
    (element as AssetField).type !== undefined && (element as AssetField).assetTypes !== undefined
  );
}

export function isDataEntryField(element: BaseField): element is DataEntryField {
  return (
    (element as DataEntryField).type !== undefined &&
    (element as DataEntryField).measurement !== undefined &&
    (element as DataEntryField).defaultUnit !== undefined &&
    (element as DataEntryField).location !== undefined
  );
}

export function isNotesField(element: BaseField): element is NotesField {
  return (element as NotesField).type !== undefined && (element as NotesField).type === 'notes';
}
